exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-approach-index-js": () => import("./../../../src/pages/approach/index.js" /* webpackChunkName: "component---src-pages-approach-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ourwork-apprenticeships-worth-it-js": () => import("./../../../src/pages/ourwork/apprenticeships-worth-it.js" /* webpackChunkName: "component---src-pages-ourwork-apprenticeships-worth-it-js" */),
  "component---src-pages-ourwork-commercial-intelligence-system-case-study-js": () => import("./../../../src/pages/ourwork/commercial-intelligence-system-case-study.js" /* webpackChunkName: "component---src-pages-ourwork-commercial-intelligence-system-case-study-js" */),
  "component---src-pages-ourwork-devops-have-we-left-something-behind-js": () => import("./../../../src/pages/ourwork/devops-have-we-left-something-behind.js" /* webpackChunkName: "component---src-pages-ourwork-devops-have-we-left-something-behind-js" */),
  "component---src-pages-ourwork-guide-to-design-thinking-js": () => import("./../../../src/pages/ourwork/guide-to-design-thinking.js" /* webpackChunkName: "component---src-pages-ourwork-guide-to-design-thinking-js" */),
  "component---src-pages-ourwork-index-js": () => import("./../../../src/pages/ourwork/index.js" /* webpackChunkName: "component---src-pages-ourwork-index-js" */),
  "component---src-pages-ourwork-usable-over-viable-js": () => import("./../../../src/pages/ourwork/usable-over-viable.js" /* webpackChunkName: "component---src-pages-ourwork-usable-over-viable-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-services-amplify-js": () => import("./../../../src/pages/services/amplify.js" /* webpackChunkName: "component---src-pages-services-amplify-js" */),
  "component---src-pages-services-define-js": () => import("./../../../src/pages/services/define.js" /* webpackChunkName: "component---src-pages-services-define-js" */),
  "component---src-pages-services-elevate-js": () => import("./../../../src/pages/services/elevate.js" /* webpackChunkName: "component---src-pages-services-elevate-js" */),
  "component---src-pages-services-ignite-js": () => import("./../../../src/pages/services/ignite.js" /* webpackChunkName: "component---src-pages-services-ignite-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-thankyou-index-js": () => import("./../../../src/pages/thankyou/index.js" /* webpackChunkName: "component---src-pages-thankyou-index-js" */)
}

